import logo from "./logo.png"

const clientData = {
    client_entity: 45,
    attorney_firm: 'Javia Law',
    attorney_name: 'Avani Javia',
    attorney_number: '214 894-3123',
    attorney_email: 'avani@javialaw.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-javia_lawfirm+(720p).mp4',
    mainColor: '#242424',
    secondaryColor: '#008ecc',
    siteLink: 'http://www.javialaw.com',
    logo
}

export default clientData